<template>
    <div class="container mx-auto px-4  mt-4" >
        <div class="flex justify-center items-center">
            <div class="basis-2/3 example_card">

                 <div class="text-left mt-5">
                    <p>Agradecemos su colaboración en el presente estudio, le recordamos que toda la información se manejará de manera confidencial y anónima. Su participación en la investigación es completamente voluntaria y si lo desea, puede abandonar la encuesta en el momento en el que lo desee. </p>
                        <br>
                    <p>A continuación se le presentarán las instrucciones, un ejemplo y un ejercicio de ensayo. </p>
                </div>

                <div class="mt-5">
                    <button type="submit" @click="next">
                        <pv-button label="Continuar" class="p-button-info"></pv-button>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import PvButton from 'primevue/button'
import { useRouter } from 'vue-router'
const router = useRouter()

const next = () => {
    router.push({ name: 'example' })
}

</script>

<style lang="scss" scoped>
.example_card {
  @apply bg-white drop-shadow;
  @apply rounded-xl w-[80%] mx-auto p-5;

  @screen md {
    @apply w-[50%];
  }
}
</style>