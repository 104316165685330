<template>
    <div class="container mx-auto px-4">
        <div class="flex justify-center">
            <div class="basis-2/3 ">

                <img src="@/assets/UADY_logo.svg" class="max-w-[300px] mx-auto"/>

                <div class="h1 font-bold  text-2xl mt-3">Facultad de Psicología</div>
                <div class="h1 font-bold  text-2xl mt-10  ">“Migración Interna en Yucatán”</div>
                
                <div class="text-left mt-5">
                    <p>
                    El presente ejercicio forma parte de una investigación que se lleva a cabo en la Universidad Autónoma de Yucatán (UADY). El objetivo es conocer las palabras que vienen a su mente al leer algunos conceptos. Sus respuestas son <b>confidenciales y anónimas</b>, se analizarán de manera global. El tiempo aproximado de respuesta es de <b>15 minutos</b>.
                    </p>
                    <br>
                    <p>
                        En el transcurso de la investigación, usted podrá solicitar información actualizada sobre la misma a la investigadora responsable. La información obtenida en esta investigación será utilizada únicamente para fines académicos.
                    </p>
                    <br>
                    <p>
                        Si tiene dudas o desea mayor información, contacte a la investigadora: Fernanda Ortiz: <a href="mailto:fernanda.ortiz@correo.uady.mx" class="text-blue-700  hover:underline">fernanda.ortiz@correo.uady.mx</a> 

                    </p>
                    <br>
                    <p>Al continuar, usted está aceptando participar en la presente investigación.</p>
                    <br><br>
                    <center>¡Muchas gracias por su participación!</center>
                </div>

                <div class="mt-5">
                    <button type="submit" @click="next">
                        <pv-button label="Continuar" class="p-button-info"></pv-button>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import PvButton from 'primevue/button'
import { useRouter } from 'vue-router'
const router = useRouter()

const next = () => {
    router.push({ name: 'form' })
}



</script>