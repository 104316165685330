import axios from 'axios'

const request = axios.create({
  baseURL: 'https://api.uadymigracion.com/api/v1',
  //baseURL: 'http://dev.tesis-api.com/api/v1',
})

request.interceptors.response.use(
  response => response,
  async error => {
    const status = (error && error.response && error.response.status) || undefined
    const errorData = (error && error.response && error.response.data) || undefined
    const message =
      (error && error.response && error.response.data && error.response.data.message) ||
      'Ocurrio un problema al procesar su petición'

    return Promise.reject({
      message,
      status,
      data: errorData,
    })
  }
)

export { request }
