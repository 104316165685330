<template>
  <form class="general_form" @submit.prevent="onSubmit" >
    <h1 class="font-bold text-xl mb-4">Datos Generales</h1>

    <div class="general_form__input">
      <label for="age">Edad</label>
      <InputText class="grow h-12" id="age" name="age" />
    </div>

    <div class="mt-4 general_form__input gap-x-4">
      <label for="sexo">Sexo</label>
      <Dropdown
        v-model="selectsValue.sexo"
        class="grow h-12 w-full md:w-auto"
        :options="sexos"
        id="sexo"
        name="sexo"
      />

      <InputText
        v-if="selectsValue.sexo == 'Personalizado'"
        class="grow h-12"
        id="sexo"
        name="sexo"
      />
    </div>

    <div class="mt-4 general_form__input gap-x-4">
      <label for="gender">Genero</label>
      <Dropdown
        v-model="selectsValue.gender"
        class="grow h-12 w-full md:w-auto"
        :options="genders"
        id="gender"
        name="gender"
      />

      <InputText
        v-if="selectsValue.gender == 'Personalizado'"
        class="grow h-12"
        id="gender"
        name="gender"
      />
    </div>
    <div class="mt-4 general_form__input">
      <label for="birthplace">Lugar de origen (Municipio y Estado)</label>
      <InputText class="grow h-12" id="birthplace" name="birthplace" />
    </div>
    <div class="mt-4 general_form__input">
      <label for="residence">Lugar de residencia (Municipio y Estado)</label>
      <InputText class="grow h-12" id="residence" name="residence" />
    </div>
    <div class="mt-4 general_form__input">
      <label for="lived_out_state">¿Ha vivido fuera del Estado de Yucatán?</label>
      <Dropdown
        v-model="selectsValue.lived_out_state"
        class="grow h-12 w-full md:w-auto"
        :options="['Sí', 'No']"
        id="lived_out_state"
        name="lived_out_state"
      />
    </div>
    <div v-if="selectsValue.lived_out_state == 'Sí'" class="mt-4 general_form__input">
      <label for="time_lived_out_state">¿Cuánto tiempo?</label>
      <InputText class="grow h-12" id="time_lived_out_state" name="time_lived_out_state" />
    </div>
    <div class="mt-4 general_form__input">
      <label for="studies">Último grado de estudios</label>
      <InputText class="grow h-12" id="studies" name="studies" />
    </div>
    <div class="mt-4 general_form__input">
      <label for="occupation">Ocupación</label>
      <InputText class="grow h-12" id="occupation" name="occupation" />
    </div>
    <div class="mt-4 general_form__input">
      <label for="religion">Religión</label>
      <InputText class="grow h-12" id="religion" name="religion" />
    </div>

    <div class="mt-5">
      <button type="submit" :disabled="loading">
        <pv-button label="Continuar" :loading="loading" class="p-button-info"></pv-button>
      </button>
    </div>
  </form>
</template>

<script setup>
import InputText from 'primevue/inputtext'
import PvButton from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import { reactive, ref } from 'vue'
import { request } from '@/utils/axios'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { store } from '@/store'

const toast = useToast()
const router = useRouter()
const selectsValue = reactive({
  gender: 'Femenino',
  sexo: 'Mujer',
  lived_out_state: 'No',
})
const loading = ref(false)

const genders = ['Femenino', 'Masculino', 'Bigénero', 'Personalizado']
const sexos = ['Mujer', 'Hombre', 'Personalizado']

const onSubmit = async e => {
  try {
    loading.value = true

    const formDataObject = { ...selectsValue }

    const body = new FormData(e.target)
    body.forEach((value, key) => (formDataObject[key] = value))

    const { data } = await request.post('/player', formDataObject)

    store.playerTesis = data

    // localStorage.setItem('player-tesis', JSON.stringify(data))

    router.push({ name: 'agradecimientos' })
  } catch (error) {
    toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 })
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.general_form {
  @apply bg-white drop-shadow;
  @apply rounded-xl w-[80%] mx-auto p-5;

  &__input {
    @apply mt-4 flex flex-wrap;
    @apply justify-between items-center;
    @apply flex-col;

    input {
      @apply w-full;
    }
  }

  @screen md {
    @apply w-[50%];

    &__input {
      @apply flex-row;

      label {
        @apply pr-5;
      }

      input {
        @apply w-auto;
      }
    }
  }
}
</style>
