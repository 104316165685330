import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './main.css'

import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import 'primevue/resources/themes/saga-blue/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css'

createApp(App).use(router).use(PrimeVue).use(ToastService).mount('#app')
